.choreInfoOuter {
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 2rem 2rem 2rem 1.5rem;
    border-radius: 1rem;
}

.choreName {
    display: block;
    font-weight: 600;
    font-size: 1.7rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
}

.choreStatOuter {
    margin-left: 0.5rem;
}