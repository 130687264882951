.choreStatContainer {
	display: inline-block;
}

.choreStatContainerPush {
	margin-left: 0.5rem;
	margin-bottom: 1rem;
}

.choreStatOuter {
	display: flex;
	align-items: center;
}

.choreStatIcon {
	margin-right: 0.5rem;
}

.choreStatText {
	font-weight: 600;
}