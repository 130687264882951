html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #191919;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow-y: hidden;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    height: 100%;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}