.heading {
    display: block;
    padding-top: 2rem;
    font-size: 2rem;
}

.subheading {
    display: block;
    color: rgba(255, 255, 255, 0.4);
    padding-bottom: 3rem;
}

.baseOuterContainer {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #191919;
    text-align: center;
}